import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

export const RightSide = () => {
  return (
    <div className='py-6'>
        <h1 className='text-xl'>Get UX Flows in Your Inbox</h1>
        <div className='relative py-4'>
        <input className='bg-gray-100 px-4 py-2 rounded-xl w-full text-[#505050]' type="text" placeholder='Enter your email address'/>
        <button className='absolute right-1 top-5 rounded-xl bg-black text-white py-2 px-4'><FaArrowRight/></button>
        </div>
        <p className='text-sm italic text-gray-500'>You can unsubscribe at any time using the link in our emails.
            For more details, review our privacy policy.</p>
        <p className='py-4 text-[#505050]'>© 2023 Mobile Page Flows. All rights reserved</p>
    </div>
  )
}
