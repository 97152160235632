import React from "react";

const Reset = () => {
  return (
    <div className="relative max-w-sm px-4 pt-20 pb-10 mx-auto mb-5 text-center lg:pt-40">
      <h1 className="py-5 text-3xl">Log in to your account</h1>
      <p className="text-xs pb-5 w-[90%] mx-auto">
        Enter the email address associated with your account and we will send
        you a link to reset your password.
      </p>
      <div className="mb-5">
        <label
          for="email"
          className="block mb-2 text-sm font-medium text-left text-gray-900"
        >
          Email address
        </label>
        <input
          placeholder="e.q johnatan@doe.com"
          type="email"
          id="email"
          className="text-md focus:bg-white bg-[#F8F8F8] border border-gray-300 text-gray-900 rounded-lg focus:ring-black outline-black focus:border-black block w-full p-2.5"
          required
        />
      </div>
      <button
        type="submit"
        className="w-full px-5 py-4 text-sm font-medium text-center text-white bg-black rounded-lg hover:text-black hover:bg-white/40 hover:border-2 focus:ring-4 focus:outline-none focus:ring-white"
      >
        Request password reset
      </button>
    </div>
  );
};
export default Reset;
