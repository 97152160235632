import { FaArrowRight } from "react-icons/fa6";
import { SiFigma } from "react-icons/si";
import { ReactComponent as Figma } from "../assets/Figma-logo.svg.svg";
export const Products = [
  {
    Header: "Checkout Digital Products",
    Header2: "Maximizing Sales and Conversions for Digital Products",
    Icon: <Figma />,
    Icon2: <FaArrowRight />,
    BtnName1: "Free Download",
    BtnName2: "Explore Flow",
    images: [
      "https://images.pexels.com/photos/19329216/pexels-photo-19329216/free-photo-of-a-dog-sitting-on-a-table-with-food-and-a-beer.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/2070485/pexels-photo-2070485.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/472309/pexels-photo-472309.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/162031/dubai-tower-arab-khalifa-162031.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/162031/dubai-tower-arab-khalifa-162031.jpeg?auto=compress&cs=tinysrgb&w=400",
    ],
  },
  {
    Header: "Filtering Many Products",
    Header2: "Exhaustive filtering flow that works well for big data",
    Icon: <Figma />,
    Icon2: <FaArrowRight />,
    BtnName1: "Free Download",
    BtnName2: "Explore Flow",
    images: [
      "https://images.pexels.com/photos/19329216/pexels-photo-19329216/free-photo-of-a-dog-sitting-on-a-table-with-food-and-a-beer.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/2070485/pexels-photo-2070485.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/472309/pexels-photo-472309.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/162031/dubai-tower-arab-khalifa-162031.jpeg?auto=compress&cs=tinysrgb&w=400",
    ],
  },
  {
    Header: "Adding Recipe",
    Header2: "12 screens",
    Icon: <Figma />,
    Icon2: <FaArrowRight />,
    BtnName1: "Free Download",
    BtnName2: "Explore Flow",
    images: [
      "https://images.pexels.com/photos/19329216/pexels-photo-19329216/free-photo-of-a-dog-sitting-on-a-table-with-food-and-a-beer.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/2070485/pexels-photo-2070485.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/472309/pexels-photo-472309.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/162031/dubai-tower-arab-khalifa-162031.jpeg?auto=compress&cs=tinysrgb&w=400",
    ],
  },
  {
    Header: "Checkout Digital Products",
    Header2: "Maximizing Sales and Conversions for Digital Products",
    Icon: <Figma />,
    Icon2: <FaArrowRight />,
    BtnName1: "Free Download",
    BtnName2: "Explore Flow",
    images: [
      "https://images.pexels.com/photos/19329216/pexels-photo-19329216/free-photo-of-a-dog-sitting-on-a-table-with-food-and-a-beer.jpeg?auto=compress&cs=tinysrgb&w=400&lazy=load",
      "https://images.pexels.com/photos/2070485/pexels-photo-2070485.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/472309/pexels-photo-472309.jpeg?auto=compress&cs=tinysrgb&w=400",
      "https://images.pexels.com/photos/162031/dubai-tower-arab-khalifa-162031.jpeg?auto=compress&cs=tinysrgb&w=400",
    ],
  },
];
