import React, { useContext, useEffect, useState } from 'react'
import NavLogo from '../assets/Logo.js'
import { AppContextProvider } from '../App.js'
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const {login, setLogin} = useContext(AppContextProvider)
  const [showHam, setShowHam] = useState(false)
  useEffect(() => {
    if(window.location.pathname === '/Pages/HomeFilter'){
      setShowHam(true)
    } else {
      setShowHam(false)
    }
  }, [])
  return (
    <div className='flex justify-between items-center p-4 px-6'>
      <a href="/">
        <div className='flex justify-between items-center space-x-2'>
          <NavLogo />
          <h1 className='font-bold'>PageFlows</h1>
        </div>
      </a>
        <div className='flex justify-between items-center space-x-4'>
          <p className='hidden lg:block'>{login ? "Don't have an account?" : 'Already a member?'}</p>
          {showHam ? <GiHamburgerMenu className='text-2xl text-gray-500 cursor-pointer'/> : <button onClick={() => setLogin(!login)} className=' text-white rounded-lg bg-black p-3 py-2'>{login ? "Sign up" : 'Log in'}</button>}
        </div>
    </div>
  )
}

export default Navbar;
