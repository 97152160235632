import React, { useState } from "react";
import Checkbox from "./Checkbox";
import { MdOutlineChevronRight } from "react-icons/md";
import { status } from "../constants";

export default function List(props) {
  const { items, compute } = props;
  const [currentState, setCurrentState] = useState("");
  const [show, setShow] = useState(false);

  const handleFilterDropDown = (item) => {
    setCurrentState(item);
  };

  return (
    <ul>
      {items.map((item) => {
        let childList = null;
        if (Array.isArray(item.items)) {
          childList = <List items={item.items} compute={compute} />;
        }
        return (
          <li key={item.id}>
            <div
              onClick={() => handleFilterDropDown(item.name)}
              className="flex items-center justify-between border-b text-lg leading-[60px] md:w-[250px]"
            >
              <div className="flex items-center space-x-2">
                {Array.isArray(item.items) && item.items.length > 0 && (
                  <MdOutlineChevronRight
                    onClick={() => setShow(!show)}
                    className={
                      show &&
                      currentState ===
                        item.name /* Updated: Use 'item.name' instead of 'items.name' */
                        ? "rotate-90 transition-all duration-300 ease-linear text-3xl"
                        : "transition-all duration-300 ease-linear rotate-0 text-3xl"
                    }
                  />
                )}
              </div>
              <div className="flex items-center justify-between w-full">
                <label htmlFor={item.name}>{item.name}</label>

                <Checkbox
                  id={item.id}
                  name={item.name}
                  checked={item.status === status.checked}
                  indeterminate={item.status === status.indeterminate}
                  compute={compute}
                  className="child w-[20px] h-6 accent-black"
                />
              </div>
            </div>
            <div
              className={
                show && currentState === item.name
                  ? "leading-[44px] text-lg pl-9 md:pl-0 transition-all duration-300 ease-linear"
                  : "hidden"
              }
            >
              {childList}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
