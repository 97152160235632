import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { MdOutlineClose } from "react-icons/md";
import { categories } from "../Data/FilterData";
import { AppContextProvider } from "../App";
import { Button } from "../utils/Button";
import List from "./List";

export const Filter = () => {
  const { filteredItems } = useContext(AppContextProvider);
  const {
    showFilter,
    setShowFilter,
    cancelBtn,
    clearFilter,
    setShowAfterApplyFilter,
    compute,
    items,
  } = useContext(AppContextProvider);

  //     const checkboxes = document.querySelectorAll('.child')
  //     const parent = document.querySelectorAll('.parent')

  //     function check(itemId) {
  //         parent.forEach((checkbox) => {
  //         checkbox.addEventListener("click", function () {
  //         if (checkbox.checked) {
  //             checkboxes.forEach((check) => {
  //             if (check.id == itemId) {

  //                 // setFilteredItems(prev => [...prev, check.value])
  //                 check.checked = true;

  //             }
  //         });
  //     } else {
  //         checkboxes.forEach((check) => {
  //             if (check.id == itemId) {
  //                 check.checked = false;

  //                 // setFilteredItems([''])
  //               }
  //             });
  //         }
  //       });
  //     });
  //   }

  return (
    <div className="px-6 md:pl-0 py-4 pb-[100px]">
      <div className="flex items-center justify-between pb-4 border-b border-gray-200 md:justify-start">
        <div></div>
        <h1 className="text-2xl">Filter Flows</h1>
        <MdOutlineClose
          onClick={() => {
            setShowFilter(!showFilter);
            document.querySelector("body").style.overflow = "scroll";
          }}
          className="text-2xl cursor-pointer md:hidden"
        />
      </div>
      <h2 className="pt-4 text-gray-500">User Flows</h2>
      <div>
        <List items={items} compute={compute} />

        <h2 className="pt-4 text-gray-500">Categories</h2>
        {categories.map((cat) => (
          <div
            className="flex items-center justify-between border-b text-lg leading-[60px]"
            key={uuidv4()}
          >
            <p>{cat.name}</p>
            <input
              className="w-[20px] h-6 accent-black rounded-lg"
              type="checkbox"
              name={cat.name}
              id={cat.name}
            />
          </div>
        ))}

        {cancelBtn ? (
          <div className="fixed bottom-0 left-0 flex items-center justify-center w-full px-6 py-6 space-x-4 bg-gray-100 md:hidden">
            <button
              onClick={() => {
                clearFilter();
                setShowFilter(!showFilter);
                document.querySelector("body").style.overflow = "scroll";
              }}
              className={
                "border rounded-xl border-black w-full bg-white  flex items-center justify-center text-center py-2.5 px-4"
              }
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="fixed bottom-0 left-0 flex items-center justify-center w-full px-6 py-6 space-x-4 bg-gray-100">
            <Button
              click={clearFilter}
              att={"border border-black w-full"}
              name={`Clear All (${filteredItems?.length})`}
            />
            <Button
              click={() => setShowAfterApplyFilter(true)}
              att={"border bg-black w-full text-white"}
              name="Apply Filters"
            />
          </div>
        )}
      </div>
    </div>
  );
};
