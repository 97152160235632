import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import "./App.css";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Reset from "./Pages/Reset";
import { HomeFilter } from "./Pages/HomeFilter";
import { filterData } from "./Data/FilterData";
import { status } from "./constants";

export const AppContextProvider = createContext();

function App() {
  const [login, setLogin] = useState(false);
  const [allFilteredItems, setAllFilteredItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [cancelBtn, setCancelBtn] = useState(true);
  const [showAfterApplyFilter, setShowAfterApplyFilter] = useState(false);

  const clearFilter = () => {
    setFilteredItems([]);
    document
      .querySelectorAll('input[type="checkbox"]')
      .forEach((e) => (e.checked = false));
    setCancelBtn(true);
  };

  useEffect(() => {
    if (filteredItems.length < 1) {
      clearFilter();
      setShowAfterApplyFilter(false);
    }
  }, [filteredItems.length]);

  const setStatus = (root, status) => {
    root.status = status;
    if (Array.isArray(root.items)) {
      return root.items.forEach((item) => {
        setStatus(item, status);
      });
    }
  };

  const computeStatus = (items) => {
    let checked = 0;
    let indeterminate = 0;

    items.forEach((item) => {
      if (item.status && item.status === status.checked) checked++;
      if (item.status && item.status === status.indeterminate) indeterminate++;
    });

    if (checked === items.length) {
      return status.checked;
    } else if (checked > 0 || indeterminate > 0) {
      return status.indeterminate;
    }
  };

  // Depth-first traversal
  const traverse = (root, needle, status) => {
    let id;
    let items;

    if (Array.isArray(root)) {
      items = root;
    } else {
      id = root.id;
      items = root.items;
    }

    // return if needle is found
    // we don't have to compute the status of the items if root.id === needle
    if (id === needle) {
      return setStatus(root, status);
    }

    if (!items) {
      return root;
    } else {
      items.forEach((item) => traverse(item, needle, status));
      root.status = computeStatus(items);
    }
  };

  const [items, setItems] = useState(filterData);
  const compute = (checkboxId, status) => {
    traverse(items, checkboxId, status);
    setItems(items.slice());
  };

  const values = {
    setAllFilteredItems,
    allFilteredItems,
    showAfterApplyFilter,
    setShowAfterApplyFilter,
    clearFilter,
    setCancelBtn,
    cancelBtn,
    showFilter,
    setShowFilter,
    login,
    setLogin,
    filteredItems,
    setFilteredItems,
    compute,
    items,
  };
  return (
    <div>
      <AppContextProvider.Provider value={values}>
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/Pages/HomeFilter" element={<HomeFilter />} />
            <Route path="/Pages/Reset" element={<Reset />} />
          </Routes>
        </BrowserRouter>
      </AppContextProvider.Provider>
    </div>
  );
}

export default App;
