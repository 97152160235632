import React from 'react'
import { Button } from '../utils/Button'

export const CallToActionBanner = () => {
  return (
    <div className='bg-[#F0EFFF] my-10 p-6 rounded-xl'>
        <p className='pb-6 text-[21px] text-center'>Unlock full-access and start designing better user experiences today</p>
        <Button att={'bg-black text-white w-full'} name={"Let's do this"} />
    </div>
  )
}
