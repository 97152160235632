import React from 'react'
import { LeftSide } from './LeftSide'
import { Middle } from './Middle'
import { RightSide } from './RightSide'

export const Footer = () => {
  return (
    <div className='px-6'>
        <LeftSide/>
        <Middle/>
        <RightSide/>
    </div>
  )
}
