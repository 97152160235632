import React from 'react'

export const Middle = () => {
  return (
    <div className='py-6 border-b flex items-center  space-x-16'>
        <div>
            <h1 className='text-xl pb-3'>Useful Info</h1>
            <ul className='leading-8'>
                <li>Terms of Service</li>
                <li>Privacy Policy</li>
                <li>FAQ</li>
                <li>We use cookies</li>
            </ul>
        </div>
        <div>
            <h1 className='text-xl pb-3'>Company</h1>
            <ul className='leading-8'>
                <li>About Us</li>
                <li>Careers</li>
                <li>Support</li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
  )
}
