import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../utils/Button";
import { ReactComponent as FilterIcon } from "../assets/Filter.svg";

import { Products } from "../Components/Products";
import { Filter } from "../Components/Filter";
import { AppContextProvider } from "../App";
import { CallToActionBanner } from "../Components/CallToActionBanner";
import { Footer } from "../Components/Footer/Footer";

export const HomeFilter = () => {
  const {
    setFilteredItems,
    filteredItems,
    showFilter,
    setShowFilter,
    clearFilter,
    showAfterApplyFilter,
  } = useContext(AppContextProvider);

  const handleDeleting = (item) => {
    const findItem = filteredItems?.filter((filterItem) => filterItem !== item);
    setFilteredItems(findItem);
  };

  const handleClick = () => {
    setShowFilter(!showFilter);
    document.querySelector("body").style.overflow = "hidden";
  };

  return (
    <>
      <div className="px-6 md:flex md:items-start md:gap-[30px]">
        <div className="flex flex-col">
          <div className="flex mt-6 border rounded-xl">
            <Button att="border w-1/2 border-black " name="Mobile" />
            <Button att="w-1/2 " name="Web" />
          </div>
          <div className="hidden md:block">
            <Filter />
          </div>
        </div>
        <Button
          click={handleClick}
          numberItems={showAfterApplyFilter ? filteredItems?.length || "" : ""}
          att="w-full bg-black text-white my-6 space-x-2 py-3 md:hidden"
          iconLeft={<FilterIcon />}
          name="Filter"
        />
        {showAfterApplyFilter ? (
          <>
            <p className="text-[21px]">
              {filteredItems.length > 0
                ? `(${filteredItems?.length}) user-flows in (12) categories filtered by:`
                : `(${filteredItems?.length}) user-flows in (12) categories`}
            </p>
            <div className="flex items-center w-full pb-6 space-x-3 overflow-x-scroll hidescroll">
              {filteredItems?.map((item) => (
                <div className="flex items-center justify-between h-12 p-4 space-x-4 text-white bg-black shrink-0 rounded-xl w-fit">
                  <p>{item}</p>
                  <button
                    onClick={() => handleDeleting(item)}
                    className="text-sm"
                  >
                    X
                  </button>
                </div>
              ))}
              {filteredItems.length > 0 ? (
                <Button
                  click={clearFilter}
                  att="shrink-0 border border-black p-3 h-12"
                  name="Clear All"
                />
              ) : (
                ""
              )}
              {/* {allFilteredItems?.map(item => (
          <div className='flex items-center justify-between h-12 p-4 space-x-4 text-white bg-black shrink-0 rounded-xl w-fit'>
            <p>{item}</p>
            <button onClick={() => handleDeleting(item)} className='text-sm'>X</button>
          </div>
        ))}
        {filteredItems.length > 0 ?
        <Button  click={clearFilter} att='shrink-0 border border-black p-3 h-12' name='Clear All'/>
        : ''
      } */}
            </div>
          </>
        ) : (
          <></>
        )}
        <div>
          {Products.map((product) => (
            <div className="" key={uuidv4()}>
              <h1 className="text-2xl">{product.Header}</h1>
              <p className="py-4 text-gray-500">{product.Header2}</p>
              <div className="flex space-x-2 md:justify-end shrink-0">
                <Button
                  iconLeft={product.Icon}
                  att="border w-1/2 space-x-2 text-sm sm:text-md md:w-[200px]"
                  name={product.BtnName1}
                />
                <Button
                  iconRight={product.Icon2}
                  att="border w-1/2 space-x-2 text-sm sm:text-md md:w-[200px]"
                  name={product.BtnName2}
                />
              </div>
              <div className="flex items-center gap-[10px] flex-wrap my-6 overflow-x-scroll hidescroll shrink-0">
                {product?.images?.map((img) => (
                  <img
                    alt=""
                    className="rounded-lg object-cover h-[380px] w-full md:w-72"
                    src={img}
                    key={uuidv4()}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className={
            showFilter
              ? "overflow-y-scroll fixed top-0 left-0 w-full bg-white h-full"
              : "hidden"
          }
        >
          <Filter />
        </div>
        <div className="md:hidden">
          <CallToActionBanner />
        </div>
      </div>
      <hr />
      <Footer />
    </>
  );
};
