export const NavLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <rect width="32" height="32" rx="8" fill="black" />
    <path
      d="M24 10.6665V21.3331C24 25.5998 23 26.6665 19 26.6665H13C9 26.6665 8 25.5998 8 21.3331V10.6665C8 6.3998 9 5.33313 13 5.33313H19C23 5.33313 24 6.3998 24 10.6665Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9.06665H14"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.334 23.3333C17.2545 23.3333 18.0007 22.5871 18.0007 21.6667C18.0007 20.7462 17.2545 20 16.334 20C15.4135 20 14.6674 20.7462 14.6674 21.6667C14.6674 22.5871 15.4135 23.3333 16.334 23.3333Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NavLogo;
