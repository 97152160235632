import React from 'react'
import NavLogo from '../../assets/Logo'
import {FaTwitter, FaDribbble, FaLinkedin, FaFigma, FaYoutube} from 'react-icons/fa'
export const LeftSide = () => {
  return (
    <div className='border-b py-6'>
        <div className='flex items-center space-x-2'>
          <NavLogo />
          <h1 className='font-bold'>PageFlows</h1>
        </div>
        <p className='py-4'>A constantly evolving platform of user-friendly flows that empowers designers to craft meaningful
stunning mobile apps.</p>
        <div className='flex space-x-4 items-center'>
            <FaDribbble/>
            <FaLinkedin/>
            <FaYoutube/>
            <FaTwitter/>
            <FaFigma/>
        </div>
     
       
  
    </div>
  )
}
