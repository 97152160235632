import React from 'react'

export const Button = ({ numberItems,click, att,name, iconLeft, iconRight}) => {
  return (
    <button onClick={click} className={`${att} flex items-center justify-center rounded-xl  text-center py-2.5 px-4`}>
      {iconLeft &&
      <div>{iconLeft}</div>
      }
      <p>{name}</p>
      {iconRight &&
      <div>{iconRight}</div>
      }
      {numberItems &&
      <div className='text-black bg-white p-3 rounded-full w-4 h-4 flex items-center justify-center '>{numberItems}</div>
      }
    </button>
  )
}
