import React, { useContext, useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { ReactComponent as Google } from "../assets/Google.svg";
import { ReactComponent as Twitter } from "../assets/Twitter.svg";
import { AppContextProvider } from "../App";
export const Form = () => {
  const [show, setShow] = useState(false);
  const [turnGreen, setTurnGreen] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [Errors, setErrors] = useState("");
  const { login, setLogin } = useContext(AppContextProvider);
  const ref = useRef(null);

  useEffect(() => {
    if (show === true) {
      ref.current.type = "text";
    } else {
      ref.current.type = "password";
    }

    login && setShowCode(false);
  }, [login, show]);

  let errors = [];

  const validatePassword = (password) => {
    if (password.length < 8) {
      errors.push("Your password must be at least 8 characters");
    }
    if (password.search(/[a-z]/i) < 0) {
      errors.push("Your password must contain at least one letter.");
    }
    if (password.search(/[0-9]/) < 0) {
      errors.push("Your password must contain at least one digit.");
    }
    if (errors.length > 0) {
      setErrors(errors.join("\n"));
      setTurnGreen(false);
    } else {
      setErrors("");
      setTurnGreen(true);
    }
  };

  return (
    <div className="max-w-sm px-4 mx-auto">
      <h1 className="py-10 text-3xl text-center">
        {login ? "Log into your account" : "Create your account"}
      </h1>
      <div className=" mx-auto mb-5 bg-[#F8F8F8] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white outline-slate-200 focus:border-white w-full p-2.5">
        <div className="flex items-center mx-auto space-x-2 w-fit">
          <Google />
          <p>Continue with Google</p>
        </div>
      </div>
      <div className="mb-5 max-w-sm mx-auto bg-[#F8F8F8] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white outline-slate-200 focus:border-white w-full p-2.5">
        <div className="flex items-center mx-auto space-x-2 w-fit">
          <Twitter />
          <p>Continue with Twitter</p>
        </div>
      </div>
      <form>
        <div className="mb-5">
          <label
            for="email"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Your email
          </label>
          <input
            type="email"
            id="email"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black outline-black focus:border-black block w-full p-2.5"
            placeholder="e.q johnatan@doe.com"
            required
          />
        </div>
        <div className={login ? "hidden" : "mb-5"}>
          <label
            for="name"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            First & Last Name
          </label>
          <input
            placeholder="e.q Johnatan Doe"
            type="text"
            id="name"
            className="focus:bg-white bg-[#F8F8F8] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black outline-black focus:border-black block w-full p-2.5"
            required
          />
        </div>
        <div className="relative mb-5">
          <div className="flex items-center justify-between">
            <label
              for="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your password
            </label>
            <a
              href="/Pages/Reset"
              onClick={() => setLogin(true)}
              className={login ? "text-xs underline" : "hidden"}
            >
              Forgot?
            </a>
          </div>
          <input
            onChange={(e) => validatePassword(e.target.value)}
            ref={ref}
            type="password"
            id="password"
            className="text-md focus:bg-white bg-[#F8F8F8] border border-gray-300 text-gray-900 rounded-lg focus:ring-black outline-black focus:border-black block w-full p-2.5"
            required
          />
          <p
            onClick={() => setShow(!show)}
            className="cursor-pointer absolute top-[43px] text-xs right-2"
          >
            Show
          </p>
        </div>
        <p className={login ? "hidden" : "text-gray-400 "}>
          Must be 8-25 characters and contain numbers and letters.
        </p>
        <p className="text-red-400">{!login && Errors}</p>
        <div className={login ? "hidden" : "flex items-center space-x-2 py-5"}>
          <div
            className={
              turnGreen
                ? "w-20 h-[3px] rounded bg-green-500"
                : "w-20 h-[3px] rounded bg-gray-300"
            }
          />
          <div
            className={
              turnGreen
                ? "w-20 h-[3px] rounded bg-green-500"
                : "w-20 h-[3px] rounded bg-gray-300"
            }
          />
          <div
            className={
              turnGreen
                ? "w-20 h-[3px] rounded bg-green-500"
                : "w-20 h-[3px] rounded bg-gray-300"
            }
          />
          <div
            className={
              turnGreen
                ? "w-20 h-[3px] rounded bg-green-500"
                : "w-20 h-[3px] rounded bg-gray-300"
            }
          />
          <div
            className={
              turnGreen
                ? "w-20 h-[3px] rounded bg-green-500"
                : "w-20 h-[3px] rounded bg-gray-300"
            }
          />
        </div>
        <div className={showCode ? "block" : "hidden"}>
          <p className="pb-5 mx-auto text-sm text-center w-80">
            We just sent you a temporary sign up code. Please check your inbox
            or spam and paste the sign up code below.
          </p>
          <div className="mb-5">
            <label
              for="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Sign up code
            </label>
            <input
              placeholder="abc-cgj-hhuuk"
              type="text"
              id="name"
              className="focus:bg-white bg-[#F8F8F8] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black outline-black focus:border-black block w-full p-2.5"
              required
            />
          </div>
        </div>
        <button
          onClick={() => setShowCode(true)}
          type="submit"
          className="w-full px-5 py-4 text-sm font-medium text-center text-white bg-black rounded-lg hover:text-black hover:bg-white/40 hover:border-2 focus:ring-4 focus:outline-none focus:ring-white"
        >
          {login ? "Log in" : "Create free account"}
        </button>
      </form>
      <p className={login ? "hidden" : "py-6 text-[#1C1C1C]"}>
        By continuing, you agree & acknowledge that you’ve read and agree to
        Mobile Page Flows <span className="underline">Terms of Services</span>{" "}
        and <span className="underline">Privacy Policy.</span>
      </p>
    </div>
  );
};
