const filterData = [
  {
    id: 1,
    name: "Onboarding",
    items: [
      {
        id: 6,
        name: "Walkthrough",
      },
      {
        id: 7,
        name: "Create account",
      },
      {
        id: 8,
        name: "Set Password",
      },
      {
        id: 9,
        name: "Verification Code",
      },
      {
        id: 10,
        name: "Topics",
      },
      {
        id: 11,
        name: "Agree to Terms",
      },
      {
        id: 12,
        name: "Cancel Signup",
      },
    ],
  },
  {
    id: 2,
    name: "Account & Profile",
    items: [
      { id: 13, name: "Magic Adventure Walkthrough" },
      { id: 14, name: "Create Your Secret Agent Account" },
      { id: 15, name: "Guardian of the Magical Password" },
      { id: 16, name: "Unicorn-Approved Verification Code" },
      { id: 17, name: "Explore Exciting Topics" },
      { id: 18, name: "Agree to the Friendship Terms" },
      { id: 19, name: "Cancel the Signup Spell" },
    ],
  },
  {
    id: 3,
    name: "Filter Products",
    items: [
      { id: 20, name: "Magic Adventure Walkthrough" },
      { id: 21, name: "Create Your Secret Agent Account" },
      { id: 22, name: "Guardian of the Magical Password" },
      { id: 23, name: "Unicorn-Approved Verification Code" },
      { id: 24, name: "Explore Exciting Topics" },
      { id: 25, name: "Agree to the Friendship Terms" },
      { id: 26, name: "Cancel the Signup Spell" },
    ],
  },
  {
    id: 4,
    name: "Ecommerce",
    items: [
      { id: 27, name: "Magic Adventure Walkthrough" },
      { id: 28, name: "Create Your Secret Agent Account" },
      { id: 29, name: "Guardian of the Magical Password" },
      { id: 30, name: "Unicorn-Approved Verification Code" },
      { id: 31, name: "Explore Exciting Topics" },
      { id: 32, name: "Agree to the Friendship Terms" },
      { id: 33, name: "Cancel the Signup Spell" },
    ],
  },
  {
    id: 5,
    name: "Analytics",
    items: [
      { id: 34, name: "Magic Adventure Walkthrough" },
      { id: 35, name: "Create Your Secret Agent Account" },
      { id: 36, name: "Guardian of the Magical Password" },
      { id: 37, name: "Unicorn-Approved Verification Code" },
      { id: 38, name: "Explore Exciting Topics" },
      { id: 39, name: "Agree to the Friendship Terms" },
      { id: 40, name: "Cancel the Signup Spell" },
    ],
  },
];

const categories = [
  {
    id: 1,
    name: "Business",
  },
  {
    id: 2,
    name: "Finance",
  },
  {
    id: 3,
    name: "Food & Drink",
  },
  {
    id: 4,
    name: "Health & Fitness",
  },
  {
    id: 5,
    name: "Medical",
  },
  {
    id: 6,
    name: "Music",
  },
  {
    id: 7,
    name: "News",
  },
  {
    id: 8,
    name: "Photo & Video",
  },
];

export { filterData, categories };
